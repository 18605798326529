.projectStyles {}

.contentStyles {
  padding-top: 15vh;
  min-height: 100vh;
  background: #C5C6CB;
}

.videoContainer {
  max-width: 75vw;
  margin: 0 auto;
}

.video {
}