.contentStyles {
  text-align: center;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  max-width: 100%;
  max-height: 100vh;
  width: auto;
  height: auto;
  margin-bottom: 15vh;
}

.words {
  display: flex;
  justify-content: space-evenly;
  font-style: italic;
  padding: 0.5vh 0;
  width: 100%;
}

.placeholder {
  max-width: 100%;
  max-height: 100vh;
  width: auto;
  height: auto;
  margin: 0;
  margin-bottom: 15vh;
}