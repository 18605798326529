.projectStyles {}

.contentStyles {
  text-align: center;
  padding-top: 15vh;
}

.image {
  max-width: 80vw;
  max-height: 90vh;
  width: auto;
  height: auto;
  margin-bottom: 15vh;
}