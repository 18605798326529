.projectStyles {}

.contentStyles {
  /* text-align: center; */
  padding: 25vh 10vw;
  display: flex;
}

.text {
  flex-grow: 1;
  text-align: left;
  padding-top: 10vh;
}

.text p {
  max-width: 500px;
  min-width: 450px;
  margin: 3vh auto;
  padding-left: 4vw;
}

.formula {
  display: block;
  margin-left: 4vw;
}

.formulaCaption {
  padding-bottom: 10vh;
}

.author {
  text-align: right;
}

.images {
  flex-grow: 2
}

.line {
  margin-bottom: 25vh;
}

.numbers {
  display: block;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 1vh;
}

.group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2vh;
}

.number {
  display: block;
  text-align: left;
  padding-bottom: 2vh;
  font-size: 14px;
  font-weight: bold;
}

.circle {
  position: relative;
  z-index: 50;
}

.circleOutside {
  height: 147px;
  width: 110px;
  float: left;
  margin-left: -110px;
  z-index: 10;
  position: relative;
}

.circleOutsideInside {
    border: solid 2px gold;
    height: 160px;
    width: 160px;
    margin-left: -25px;
    margin-top: -30px;
    border-radius: 80px;
}

.placeholder {
  margin-right: 0;
  background: lightgray;
  border: 1px solid white;
}