.projectStyles {}

.contentStyles {
  text-align: center;
  padding-top: 15vh;
  padding-bottom: 15vh;
  min-height: 100vh;
  background: #303030;
}

.title {
  color: white;
  font-weight: bold;
  font-size: 14px;
  display: block;
  padding: 0 0 3vh 0;
}

.video {
  max-width: 90vw;
  margin: 0 auto 15vh auto;
}