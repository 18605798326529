.overlay {
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 900;
}

.overlayIsClosed {
  height: 60px;
  overflow-y: hidden;
  background: transparent;
}

.overlayContent {
  width: 100%;
  min-height: 100vh;
  padding-top: 60px;
}

.list {
  padding-top: 12vh;
  margin: 0 auto;
  max-width: 600px;
}