.projectStyles {}

.contentStyles {
  text-align: center;
  padding-top: 15vh;
  min-height: 100vh;
  max-width: 75vw;
  margin: 0 auto;
}

.title {
  text-align: left;
  display: block;
  padding: 0 0 4vh 0;
}

.video {
  margin: 0 auto 12vh auto;
}