.word {
  padding: 5px 0;
  overflow : 'auto';
}
.word:hover {
  /* color: gray; */
  text-shadow: 5px 5px #bababa;
}

.image {
}