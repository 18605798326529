.projectStyles {}

.contentStyles {
  padding: 15vh 20vw 0 20vw;
}

.scrollRight {
  display: block;
  width: 400px;
  margin-left: 7vh;
  font-weight: bold;
  text-align: center;
  padding-top: 4vh;
}

.images {
  display: flex;
}

.singleNumber {
  display: block;
  min-width: 400px;
  max-width: 400px;
  width: 400px;
  height: 600px;
  border: 1px solid lightgray;
  font-family: "Source Sans Pro", sans-serif;
  text-align: center;
  font-size: 12px;
  padding-top: 200px;
}

.singleNumber ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.singleImageLight, .singleNumber {
  margin-right: 5vw;
  margin-left: 5vw;
}

.noMargin {
  margin-right: 0;
  margin-left: 0;
}
.noMarginRight {
  margin-right: 0;
}
.noMarginLeft {
  margin-left: 0;
}