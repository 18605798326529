.title {
  margin: 0;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 8px;
  display: inline-block;
  line-height: 1.3;
  padding: 3px;
  /* background: white; */
}

.title:hover {
  cursor: pointer;
}

.title:hover .link {
  border-bottom: 1px solid black;
}

.title:hover:after {
  content: ' →';
}

.isParent {
  font-size: 1.1rem;
  text-transform: lowercase;
  font-weight: normal;
}
.isParent:hover {
  cursor: auto;
}
.isParent:before {
  content: '...';
}
.isParent:hover:after {
  content: '';
}

.link {
  color: black;
  text-decoration: none;
}

.linkIsActive {
  border-bottom: 1px solid black;
}