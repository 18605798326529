.projectStyles {}

.contentStyles {
  text-align: center;
  padding: 20vh 10vw 10vh 10vw;
}

.row {
  display: flex;
  width: calc(13 * 550px);
  margin-bottom: 15vh;
}

.image {
  margin-right: 50px;
}