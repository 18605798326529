.header {
  position: fixed;
  height: 60px;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 14px 18px;
  border-bottom: 1px solid transparent;
  z-index: 1000;
}

.button {
  -webkit-appearance: none;
  display: flex;
  align-items: stretch;
  margin: 0;
  cursor: pointer;
  background: transparent;
  border: none;
  font-family: "Computer Modern Serif", serif;
}

.menuButton {
  composes: button;
  flex-direction: row-reverse;
  font-size: 16px;
}

.name {
  margin: 0 0 0 16px;
  font-size: 18px;
  font-family: "Computer Modern Serif", serif;
}

.label {
  display: none;
  position: relative;
  margin-left: 10px;
  letter-spacing: 1px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.833rem;
  text-transform: uppercase;
  color: gray;
}
.header:hover .label,
.isActive .label {
  display: flex;
  justify-content:center;
  align-content:center;
  flex-direction:column;
}

.header:hover{
  background: white;
  border-bottom: 1px solid black;
}

.headerWithOpenNav:hover {
  border-bottom: 1px solid transparent;
}

.icon {
  height: 2px;
  width: 18px;
  background-color: black;
  position: relative;
  top: 0px;
  left: 0;
  margin: auto;
  transition: all 100ms ease-in-out;
}

.icon:before {
  content: "";
  height: 2px;
  width: 10px;
  box-shadow: 0 -5px 0 0 black;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 100ms ease-in-out;
}

.menuButton:hover .icon:before {
  width: 18px;
  box-shadow: 0 -5px 0 0 black;
}

.isActive .icon {
  transform: rotate(45deg);
  background-color: black;
  transition: all 100ms ease-in-out;
}
.isActive .icon:before {
  top: 0;
  transform: translate(-1px, -4px) rotate(90deg);
}
.menuButton.isActive:hover .icon:before {
  width: 10px;
  box-shadow: 0 -5px 0 0 black;
}
.isActive .icon:after {
  top: 0;
  transform: translate(9px, -4px) rotate(90deg);
}
.menuButton.isActive:hover .icon:after {
  width: 10px;
  box-shadow: 8px 5px 0 0 black;
}

.icon:after {
  content: "";
  height: 2px;
  width: 10px;
  box-shadow: 8px 5px 0 0 black;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 100ms ease-in-out;
}

.menuButton:hover .icon:after {
  width: 18px;
  box-shadow: 0px 5px 0 0 black;
}

.nextLink {
  display: flex;
  align-items: stretch;
  margin: 0;
  cursor: pointer;
  text-align: right;
  padding: 0 30px 0 0;
  position: relative;
  flex-direction: column;
  text-decoration: none;
  color: black;
}

.nextLink:after {
  content:' →';
  font-size: 1rem;
  color: gray;
  font-weight: bold;
  position: absolute;
  top: 5px;
  right: 5px;
}

.nextLink:hover:after {
  color: black;
  font-weight: bold;
  right: 0;
}

.nextTitle {
  font-weight: bold;
  font-size: 15px;
  padding-top: 4px;
  line-height: 14px;
}

.nextLink:hover .nextTitle {
  border-bottom: 1px solid black;
}

.nextLabel {
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  font-size: 12px;
  color: gray;
}