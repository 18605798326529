.projectStyles {}

.contentStyles {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  padding: 100px 50px 100px 50px;
}
.image {
  margin-right: 50px;
  max-width: 100%;
  height: auto;
}
.row {
  /* display: flex; */
  /* width: 14650px; */
  padding-left: 584px;
  width: calc(25 * 584px);
  margin-bottom: 50px;
}

.rowWithImage {
  composes: row;
  padding-left: 0;
}
