html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* cursor: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52 (66869) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Earrow_cursor_plain%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='arrow_cursor_plain' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M5,9 L5,17 L0,17 L0,0 L5,0 L17,0 L17,5 L9,5 L27,23 L23,27 L5,9 Z' id='arrow' fill='%23000000' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/svg%3E"), pointer; */
}

@font-face {
  font-family: "Computer Modern Serif";
  src: url('./fonts/cmunrm-webfont.eot');
  src: url('./fonts/cmunrm-webfont.eot?#iefix') format('embedded-opentype'),
  url('./fonts/cmunrm.otf') format('opentype'),
  url('./fonts/cmunrm-webfont.woff') format('woff'),
  url('./fonts/cmunrm-webfont.ttf') format('truetype'),
  url('./fonts/cmunrm-webfont.svg#cmu_serifroman') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Computer Modern Serif";
  src: url('./fonts/cmunsl-webfont.eot');
  src: url('./fonts/cmunsl-webfont.eot?#iefix') format('embedded-opentype'),
  url('./fonts/cmunsl.otf') format('opentype'),
  url('./fonts/cmunsl-webfont.woff') format('woff'),
  url('./fonts/cmunsl-webfont.ttf') format('truetype'),
  url('./fonts/cmunsl-webfont.svg#cmu_serif_extraromanslanted') format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Computer Modern Serif";
  src: url('./fonts/cmunbl-webfont.eot');
  src: url('./fonts/cmunbl-webfont.eot?#iefix') format('embedded-opentype'),
  url('./fonts/cmunbl.otf') format('opentype'),
  url('./fonts/cmunbl-webfont.woff') format('woff'),
  url('./fonts/cmunbl-webfont.ttf') format('truetype'),
  url('./fonts/cmunbl-webfont.svg#cmu_serif_extraboldslanted') format('svg');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Computer Modern Serif";
  src: url('./fonts/cmunbx-webfont.eot');
  src: url('./fonts/cmunbx-webfont.eot?#iefix') format('embedded-opentype'),
  url('./fonts/cmunbx.otf') format('opentype'),
  url('./fonts/cmunbx-webfont.woff') format('woff'),
  url('./fonts/cmunbx-webfont.ttf') format('truetype'),
  url('./fonts/cmunbx-webfont.svg#cmu_serifbold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

body {
  font-family: "Computer Modern Serif", serif;
}

body:not(.focus-visible) button:focus {
  outline: none;
}

// Remove focus style only on mouse, keep on keyboard navigation
body:not(.focus-visible) button:focus,
body:not(.focus-visible) input:focus,
body:not(.focus-visible) select:focus,
body:not(.focus-visible) textarea:focus,{
  outline: none;
}

.no-scroll {
  overflow: hidden;
}