.projectStyles {}

.contentStyles {
  /* width: 90vw; */
  max-width: 90vw;
  padding: 15vh 0 20vh 0;
  margin: 0 auto;
}

.video {
}

.row {
  padding-top: 15vh;
}
.arbol {
  max-width: 25%;
}
.arboles {
  padding-top: 15vh;
}
.plant {
  margin-top: 20vh;
  max-width: 35%;
}
.rastro {
  max-width: 40%;
}
.secondPlant {
  max-width: 40%;
}
.thirdPlant {
  max-width: 40%;
}
.plaza {
  max-width: 40%;
}
.gloves {
  margin-top: 15vh;
  max-width: 35%;
}
.light {
  margin-top: 15vh;
  max-width: 20%;
}