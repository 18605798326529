.video {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.video iframe, .video object, .video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}