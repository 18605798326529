.intro {
  margin-bottom: 20vh;
}

.title {
  font-size: 1.7rem;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.subtitle {
  font-size: 1.1rem;
  margin: 0;
  font-weight: normal;
}