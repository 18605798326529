.projectStyles {}

.contentStyles {
  text-align: center;
  background: darkblue;
  color: white;
  background: linear-gradient(darkblue, white);
  font-size: 24px;
  padding-bottom: 0;
  /* padding: 10vh 30vw; */
}

.image {
  position: absolute;
  top: 95vh;
  left: 5vw;
}