.projectStyles {}

.contentStyles {
  padding-top: 15vh;
  padding-bottom: 20vh;
  max-width: 740px;
  margin: 0 auto;
  text-align: center;
}

.video {
  max-width: 90vw;
  margin: 0 auto;
}
.singleImageContainer {
  padding-top: 10vh;
  position: relative;
}
.singleImage {
  position: relative;
  max-width: 90vw;
  height: auto;
}
.singleImageContainer:before {
  content:"";
  display: block;
  position: relative;
  margin: 0 auto;
  top: -5vh;
  width: 1px;
  height: 10vh;
  background: black;
}

.framesNumber {
  display: block;
  padding: 0 0 2vh 0;
}