.projectStyles {}

.contentStyles {
  padding-top: 20vh;
  margin: 0 auto;
  text-align: center;
}

.video {
  position: relative;
  max-height: 647px;
  height: 90vh;
}

.video iframe, .video object, .video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.timeline {
  margin: 15vh auto;
}

.set {
  padding: 10vh 0;
  width: 400px;
  max-width: 90vw;
  margin: 0 auto;
  text-align: center;
}

.setNumber {
  display: block;
  padding: 5vh 0;
  font-weight: bold;
}

.image {
  display: block;
  margin: 0 auto 10vh auto;
  max-width: 90vw;
  max-height: 90vh;
  width: auto;
  height: auto;
}

/* Explanation styles */
.explanationTable {
  width: 100%;
  /* border: 1px solid black; */
  border-collapse: collapse;
  border-spacing: 0px;
}

.explanationTable td {
  border: 1px solid black;
  padding: 4px;
}