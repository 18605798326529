.title {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 0.833rem;
  margin: 0 0 6vh 0;
  color: gray;
  letter-spacing: 1px;
  font-family: "Source Sans Pro", sans-serif;
}

.listSection {
  margin: 0 0 15vh 0;
}

.list {
  margin: 0 0 10px 0;
  padding: 0;
  list-style: none;
  width: 500px;
}

.subList {
  composes: list;
  padding-left: 28px;
}