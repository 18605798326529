.explanation {
  position: fixed;
  min-width: 450px;
  max-width: 500px;
  max-height: 75vh;
  top: 80px;
  right: 30px;
  padding: 30px;
  border: 1px solid transparent;
  overflow-y: hidden;
}

.explanation:hover {
  background: white;
  border: 1px solid black;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.explanation.explanationIsHidden {
  min-width: auto;
  padding: 0;
  top: 80px;
}

.collapseButton {
  -webkit-appearance: none;
  margin: 0 0 0 0;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  border: none;
  background: none;
  color: red;
  float: right;
  font-family: "Computer Modern Serif", serif;
}

.explanationIsHidden .collapseButton {
  color: black;
  margin: 0;
}

.projectParent {
  font-size: 1.1rem;
  text-transform: lowercase;
  font-weight: normal;
  margin: 0 0 0.5rem 0;
}
.projectTitle {
  font-size: 1.25rem;
  margin: 0 0 3rem 0;
}