.scale {
  background: gray;
  height: 300px;
  width: 36px;
  position: fixed;
  bottom: 3.5vh;
  right: 2.5vw;
}

.color {
  background: gray;
}