.projectStyles {}

.contentStyles {
  width: 75vw;
  max-width: 80vh;
  padding-top: 70px;
  margin: 0 auto;
}

.video {
  margin-bottom: 10vh;
  border: 1px solid gray;
}