@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* cursor: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 52 (66869) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Earrow_cursor_plain%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='arrow_cursor_plain' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M5,9 L5,17 L0,17 L0,0 L5,0 L17,0 L17,5 L9,5 L27,23 L23,27 L5,9 Z' id='arrow' fill='%23000000' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/svg%3E"), pointer; */
}

@font-face {
  font-family: "Computer Modern Serif";
  src: url(/static/media/cmunrm-webfont.0517fcac.eot);
  src: url(/static/media/cmunrm-webfont.0517fcac.eot?#iefix) format('embedded-opentype'),
  url(/static/media/cmunrm.61e714a9.otf) format('opentype'),
  url(/static/media/cmunrm-webfont.e963963f.woff) format('woff'),
  url(/static/media/cmunrm-webfont.f8d9bcf4.ttf) format('truetype'),
  url(/static/media/cmunrm-webfont.2511c3b6.svg#cmu_serifroman) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Computer Modern Serif";
  src: url(/static/media/cmunsl-webfont.9b59efdd.eot);
  src: url(/static/media/cmunsl-webfont.9b59efdd.eot?#iefix) format('embedded-opentype'),
  url(/static/media/cmunsl.7ee88cf3.otf) format('opentype'),
  url(/static/media/cmunsl-webfont.221522ac.woff) format('woff'),
  url(/static/media/cmunsl-webfont.205b4087.ttf) format('truetype'),
  url(/static/media/cmunsl-webfont.9eda9401.svg#cmu_serif_extraromanslanted) format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Computer Modern Serif";
  src: url(/static/media/cmunbl-webfont.6df2b5d8.eot);
  src: url(/static/media/cmunbl-webfont.6df2b5d8.eot?#iefix) format('embedded-opentype'),
  url(/static/media/cmunbl.f6dd8948.otf) format('opentype'),
  url(/static/media/cmunbl-webfont.38a9ab1a.woff) format('woff'),
  url(/static/media/cmunbl-webfont.37b7bbba.ttf) format('truetype'),
  url(/static/media/cmunbl-webfont.5ee22f40.svg#cmu_serif_extraboldslanted) format('svg');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Computer Modern Serif";
  src: url(/static/media/cmunbx-webfont.5ede1935.eot);
  src: url(/static/media/cmunbx-webfont.5ede1935.eot?#iefix) format('embedded-opentype'),
  url(/static/media/cmunbx.778c19c6.otf) format('opentype'),
  url(/static/media/cmunbx-webfont.690a4fa8.woff) format('woff'),
  url(/static/media/cmunbx-webfont.c8565d5e.ttf) format('truetype'),
  url(/static/media/cmunbx-webfont.fca2f5df.svg#cmu_serifbold) format('svg');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "Computer Modern Serif", serif;
}

body:not(.focus-visible) button:focus {
  outline: none;
}

// Remove focus style only on mouse, keep on keyboard navigation
body:not(.focus-visible) button:focus,
body:not(.focus-visible) input:focus,
body:not(.focus-visible) select:focus,
body:not(.focus-visible) textarea:focus,{
  outline: none;
}

.no-scroll {
  overflow: hidden;
}
/* @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Serif:400,400i,700'); */

.App {
  font-family: serif;
}
button {
  font-family: serif;
}
.TheArt_theArt__1YGKo {

}
.Header_header__1XWkm {
  position: fixed;
  height: 60px;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 14px 18px;
  border-bottom: 1px solid transparent;
  z-index: 1000;
}

.Header_button__2fQz- {
  -webkit-appearance: none;
  display: flex;
  align-items: stretch;
  margin: 0;
  cursor: pointer;
  background: transparent;
  border: none;
  font-family: "Computer Modern Serif", serif;
}

.Header_menuButton__1HWId {
  flex-direction: row-reverse;
  font-size: 16px;
}

.Header_name__2OZhm {
  margin: 0 0 0 16px;
  font-size: 18px;
  font-family: "Computer Modern Serif", serif;
}

.Header_label__1GHMa {
  display: none;
  position: relative;
  margin-left: 10px;
  letter-spacing: 1px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.833rem;
  text-transform: uppercase;
  color: gray;
}
.Header_header__1XWkm:hover .Header_label__1GHMa,
.Header_isActive__aDfa8 .Header_label__1GHMa {
  display: flex;
  justify-content:center;
  align-content:center;
  flex-direction:column;
}

.Header_header__1XWkm:hover{
  background: white;
  border-bottom: 1px solid black;
}

.Header_headerWithOpenNav__AmhAq:hover {
  border-bottom: 1px solid transparent;
}

.Header_icon__397i- {
  height: 2px;
  width: 18px;
  background-color: black;
  position: relative;
  top: 0px;
  left: 0;
  margin: auto;
  transition: all 100ms ease-in-out;
}

.Header_icon__397i-:before {
  content: "";
  height: 2px;
  width: 10px;
  box-shadow: 0 -5px 0 0 black;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 100ms ease-in-out;
}

.Header_menuButton__1HWId:hover .Header_icon__397i-:before {
  width: 18px;
  box-shadow: 0 -5px 0 0 black;
}

.Header_isActive__aDfa8 .Header_icon__397i- {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: black;
  transition: all 100ms ease-in-out;
}
.Header_isActive__aDfa8 .Header_icon__397i-:before {
  top: 0;
  -webkit-transform: translate(-1px, -4px) rotate(90deg);
          transform: translate(-1px, -4px) rotate(90deg);
}
.Header_menuButton__1HWId.Header_isActive__aDfa8:hover .Header_icon__397i-:before {
  width: 10px;
  box-shadow: 0 -5px 0 0 black;
}
.Header_isActive__aDfa8 .Header_icon__397i-:after {
  top: 0;
  -webkit-transform: translate(9px, -4px) rotate(90deg);
          transform: translate(9px, -4px) rotate(90deg);
}
.Header_menuButton__1HWId.Header_isActive__aDfa8:hover .Header_icon__397i-:after {
  width: 10px;
  box-shadow: 8px 5px 0 0 black;
}

.Header_icon__397i-:after {
  content: "";
  height: 2px;
  width: 10px;
  box-shadow: 8px 5px 0 0 black;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 100ms ease-in-out;
}

.Header_menuButton__1HWId:hover .Header_icon__397i-:after {
  width: 18px;
  box-shadow: 0px 5px 0 0 black;
}

.Header_nextLink__2LfuG {
  display: flex;
  align-items: stretch;
  margin: 0;
  cursor: pointer;
  text-align: right;
  padding: 0 30px 0 0;
  position: relative;
  flex-direction: column;
  text-decoration: none;
  color: black;
}

.Header_nextLink__2LfuG:after {
  content:' \2192';
  font-size: 1rem;
  color: gray;
  font-weight: bold;
  position: absolute;
  top: 5px;
  right: 5px;
}

.Header_nextLink__2LfuG:hover:after {
  color: black;
  font-weight: bold;
  right: 0;
}

.Header_nextTitle__1lP00 {
  font-weight: bold;
  font-size: 15px;
  padding-top: 4px;
  line-height: 14px;
}

.Header_nextLink__2LfuG:hover .Header_nextTitle__1lP00 {
  border-bottom: 1px solid black;
}

.Header_nextLabel__NmD6s {
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  font-size: 12px;
  color: gray;
}
.Intro_intro__3Dyi5 {
  margin-bottom: 20vh;
}

.Intro_title__1eSVu {
  font-size: 1.7rem;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.Intro_subtitle__3F3yk {
  font-size: 1.1rem;
  margin: 0;
  font-weight: normal;
}
.Overlay_overlay__2GgdU {
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 900;
}

.Overlay_overlayIsClosed__2GSGb {
  height: 60px;
  overflow-y: hidden;
  background: transparent;
}

.Overlay_overlayContent__3bvgK {
  width: 100%;
  min-height: 100vh;
  padding-top: 60px;
}

.Overlay_list__3OZE8 {
  padding-top: 12vh;
  margin: 0 auto;
  max-width: 600px;
}
.List_title__fTyE5 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 0.833rem;
  margin: 0 0 6vh 0;
  color: gray;
  letter-spacing: 1px;
  font-family: "Source Sans Pro", sans-serif;
}

.List_listSection__3YBic {
  margin: 0 0 15vh 0;
}

.List_list__30PRf {
  margin: 0 0 10px 0;
  padding: 0;
  list-style: none;
  width: 500px;
}

.List_subList__2lpn1 {
  padding-left: 28px;
}
.ProjectTitle_title__37QR0 {
  margin: 0;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 8px;
  display: inline-block;
  line-height: 1.3;
  padding: 3px;
  /* background: white; */
}

.ProjectTitle_title__37QR0:hover {
  cursor: pointer;
}

.ProjectTitle_title__37QR0:hover .ProjectTitle_link__3q-y6 {
  border-bottom: 1px solid black;
}

.ProjectTitle_title__37QR0:hover:after {
  content: ' \2192';
}

.ProjectTitle_isParent__2_kwh {
  font-size: 1.1rem;
  text-transform: lowercase;
  font-weight: normal;
}
.ProjectTitle_isParent__2_kwh:hover {
  cursor: auto;
}
.ProjectTitle_isParent__2_kwh:before {
  content: '...';
}
.ProjectTitle_isParent__2_kwh:hover:after {
  content: '';
}

.ProjectTitle_link__3q-y6 {
  color: black;
  text-decoration: none;
}

.ProjectTitle_linkIsActive__lxxKt {
  border-bottom: 1px solid black;
}
.Word_word__2_cxz {
  padding: 5px 0;
  overflow : 'auto';
}
.Word_word__2_cxz:hover {
  /* color: gray; */
  text-shadow: 5px 5px #bababa;
}

.Word_image__1ZRpF {
}
.styles_projectStyles__1L086 {}

.styles_contentStyles__df6a_ {
  text-align: center;
  padding-top: 15vh;
  min-height: 100vh;
  max-width: 75vw;
  margin: 0 auto;
}

.styles_title__2PUuo {
  text-align: left;
  display: block;
  padding: 0 0 4vh 0;
}

.styles_video__3H2xX {
  margin: 0 auto 12vh auto;
}
.VimeoVideo_video__2_Tld {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.VimeoVideo_video__2_Tld iframe, .VimeoVideo_video__2_Tld object, .VimeoVideo_video__2_Tld embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.styles_contentStyles__3_8vx {
  background: #CCD4BE;
  min-height: 100vh;
}
.styles_videoContainer__1-Jwj {
  width: 75vw;
  max-width: 1200px;
  padding-top: 15vh;
  margin: 0 auto;
}
.styles_projectStyles__KHN1P {}

.styles_contentStyles__juOab {
  text-align: center;
  padding-top: 15vh;
  padding-bottom: 15vh;
  min-height: 100vh;
  background: #303030;
}

.styles_title__2Gdtc {
  color: white;
  font-weight: bold;
  font-size: 14px;
  display: block;
  padding: 0 0 3vh 0;
}

.styles_video__1ODuQ {
  max-width: 90vw;
  margin: 0 auto 15vh auto;
}
.styles_contentStyles__1B9Ge {
  text-align: center;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styles_image__3NLK1 {
  max-width: 100%;
  max-height: 80vh;
  width: auto;
  height: auto;
  margin-bottom: 15vh;
}

.styles_words__GrUBC {
  display: flex;
  justify-content: space-evenly;
  font-style: italic;
  padding: 0.5vh 0;
  width: 100%;
}

.styles_placeholder__1VRl7 {
  max-width: 100%;
  max-height: 100vh;
  width: auto;
  height: auto;
  margin: 0;
  margin-bottom: 15vh;
}
.ImagePlaceholder_placeholder__1yC7U {
  margin-right: 50px;
  max-width: 100%;
  height: auto;
  display: inline-flex;
  border: 1px solid black;
  color: black;
  align-items: center;
  justify-content: center;
}
.styles_contentStyles__PgVDS {
  text-align: center;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styles_image__1isYP {
  max-width: 100%;
  max-height: 80vh;
  width: auto;
  height: auto;
  margin-bottom: 15vh;
}

.styles_words__2OU3g {
  display: flex;
  justify-content: space-evenly;
  font-style: italic;
  padding: 0.5vh 0;
  width: 100%;
}

.styles_placeholder__3QpvL {
  max-width: 100%;
  max-height: 100vh;
  width: auto;
  height: auto;
  margin: 0;
  margin-bottom: 15vh;
}
.styles_contentStyles__1BNyT {
  text-align: center;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styles_image__2Kgjd {
  max-width: 100%;
  max-height: 80vh;
  width: auto;
  height: auto;
  margin-bottom: 15vh;
}

.styles_words__2heXB {
  display: flex;
  justify-content: space-evenly;
  font-style: italic;
  padding: 0.5vh 0;
  width: 100%;
}

.styles_placeholder__1jeeo {
  max-width: 100%;
  max-height: 100vh;
  width: auto;
  height: auto;
  margin: 0;
  margin-bottom: 15vh;
}
.styles_contentStyles__SZSXq {
  text-align: center;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styles_image__18UUO {
  max-width: 100%;
  max-height: 80vh;
  width: auto;
  height: auto;
  margin-bottom: 15vh;
}

.styles_placeholder__1G4bj {
  max-width: 100%;
  max-height: 100vh;
  width: auto;
  height: auto;
  margin: 0;
  margin-bottom: 15vh;
}
.styles_contentStyles__uLE7o {
  text-align: center;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: gray;
}

.styles_image__2g2-9 {
  max-width: 100%;
  max-height: 80vh;
  width: auto;
  height: auto;
  margin-bottom: 15vh;
}

.styles_placeholder__AYDoH {
  max-width: 100%;
  max-height: 100vh;
  width: auto;
  height: auto;
  margin: 0;
  margin-bottom: 15vh;
}
.styles_contentStyles__3RgTq {
  text-align: center;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styles_image__2AnxH {
  max-width: 100%;
  max-height: 75vh;
  width: auto;
  height: auto;
}

.styles_title__3u_3Z {
  margin-bottom: 15vh;
}

.styles_placeholder__1GlEJ {
  max-width: 100%;
  max-height: 100vh;
  width: auto;
  height: auto;
  margin: 0;
  margin-bottom: 15vh;
}
.styles_projectStyles__27ftP {}

.styles_contentStyles__1oGQ_ {
  width: 75vw;
  max-width: 1200px;
  padding-top: 70px;
  margin: 0 auto;
}
.styles_projectStyles__2i5et {}

.styles_contentStyles__3zXOc {
  text-align: center;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styles_image__9cvJ_ {
  max-width: 100%;
  height: auto;
  margin-bottom: 15vh;
}
.styles_projectStyles__1dBPG {}

.styles_contentStyles__irT_S {
  text-align: center;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styles_image__3Hshf {
  max-width: 100%;
  height: auto;
  margin-bottom: 15vh;
}
.styles_projectStyles__3PFir {}

.styles_contentStyles__3IFst {
  padding-top: 15vh;
  min-height: 100vh;
  background: #C5C6CB;
}

.styles_videoContainer__GiIDh {
  max-width: 75vw;
  margin: 0 auto;
}

.styles_video__3303G {
}
.styles_projectStyles__2pVc8 {}

.styles_contentStyles__1pbkX {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  padding: 100px 50px 100px 50px;
}
.styles_image__1vTVQ {
  margin-right: 50px;
  max-width: 100%;
  height: auto;
}
.styles_row__3f_Mk {
  /* display: flex; */
  /* width: 14650px; */
  padding-left: 584px;
  width: calc(25 * 584px);
  margin-bottom: 50px;
}

.styles_rowWithImage__2FimN {
  padding-left: 0;
}

.styles_projectStyles__1yCoB {}

.styles_contentStyles__uT5BA {
  text-align: center;
  padding-top: 15vh;
}

.styles_image__7uRJT {
  max-width: 80vw;
  max-height: 90vh;
  width: auto;
  height: auto;
  margin-bottom: 15vh;
}
.styles_projectStyles__3CxFF {}

.styles_contentStyles__17eVk {
  text-align: center;
  background: darkblue;
  color: white;
  background: linear-gradient(darkblue, white);
  font-size: 24px;
  padding-bottom: 0;
  /* padding: 10vh 30vw; */
}

.styles_image__2a4HL {
  position: absolute;
  top: 95vh;
  left: 5vw;
}
.styles_projectStyles__AoerJ {}

.styles_contentStyles__GQrsl {
  padding: 15vh 20vw 0 20vw;
}

.styles_scrollRight___Zr_f {
  display: block;
  width: 400px;
  margin-left: 7vh;
  font-weight: bold;
  text-align: center;
  padding-top: 4vh;
}

.styles_images__stEKY {
  display: flex;
}

.styles_singleNumber__3JFVz {
  display: block;
  min-width: 400px;
  max-width: 400px;
  width: 400px;
  height: 600px;
  border: 1px solid lightgray;
  font-family: "Source Sans Pro", sans-serif;
  text-align: center;
  font-size: 12px;
  padding-top: 200px;
}

.styles_singleNumber__3JFVz ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.styles_singleImageLight__uWMeP, .styles_singleNumber__3JFVz {
  margin-right: 5vw;
  margin-left: 5vw;
}

.styles_noMargin__1kqKS {
  margin-right: 0;
  margin-left: 0;
}
.styles_noMarginRight__68L8V {
  margin-right: 0;
}
.styles_noMarginLeft__3qxd7 {
  margin-left: 0;
}
.styles_projectStyles__1gBB3 {}

.styles_contentStyles__S6o37 {
  /* text-align: center; */
  padding: 25vh 10vw;
  display: flex;
}

.styles_text__19riG {
  flex-grow: 1;
  text-align: left;
  padding-top: 10vh;
}

.styles_text__19riG p {
  max-width: 500px;
  min-width: 450px;
  margin: 3vh auto;
  padding-left: 4vw;
}

.styles_formula__99GnP {
  display: block;
  margin-left: 4vw;
}

.styles_formulaCaption__2aF13 {
  padding-bottom: 10vh;
}

.styles_author__3FmJ4 {
  text-align: right;
}

.styles_images__3iTHW {
  flex-grow: 2
}

.styles_line__3M4OX {
  margin-bottom: 25vh;
}

.styles_numbers__E4uOh {
  display: block;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 1vh;
}

.styles_group__1KXoS {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2vh;
}

.styles_number__2_dag {
  display: block;
  text-align: left;
  padding-bottom: 2vh;
  font-size: 14px;
  font-weight: bold;
}

.styles_circle__1Dzs9 {
  position: relative;
  z-index: 50;
}

.styles_circleOutside__1Q6Ly {
  height: 147px;
  width: 110px;
  float: left;
  margin-left: -110px;
  z-index: 10;
  position: relative;
}

.styles_circleOutsideInside__2nOyB {
    border: solid 2px gold;
    height: 160px;
    width: 160px;
    margin-left: -25px;
    margin-top: -30px;
    border-radius: 80px;
}

.styles_placeholder__2LPl- {
  margin-right: 0;
  background: lightgray;
  border: 1px solid white;
}
.styles_projectStyles__3s4LD {}

.styles_contentStyles__Pl_Aw {
  padding-top: 15vh;
  padding-bottom: 20vh;
  max-width: 740px;
  margin: 0 auto;
  text-align: center;
}

.styles_video__3yizX {
  max-width: 90vw;
  margin: 0 auto;
}
.styles_singleImageContainer__2EIAZ {
  padding-top: 10vh;
  position: relative;
}
.styles_singleImage__1hcJB {
  position: relative;
  max-width: 90vw;
  height: auto;
}
.styles_singleImageContainer__2EIAZ:before {
  content:"";
  display: block;
  position: relative;
  margin: 0 auto;
  top: -5vh;
  width: 1px;
  height: 10vh;
  background: black;
}

.styles_framesNumber__3FnGa {
  display: block;
  padding: 0 0 2vh 0;
}
.styles_projectStyles__LcM9B {}

.styles_contentStyles__dMKaZ {
  padding-top: 20vh;
  margin: 0 auto;
  text-align: center;
}

.styles_video__1GEuN {
  position: relative;
  max-height: 647px;
  height: 90vh;
}

.styles_video__1GEuN iframe, .styles_video__1GEuN object, .styles_video__1GEuN embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.styles_timeline__2SlyP {
  margin: 15vh auto;
}

.styles_set__1UcH7 {
  padding: 10vh 0;
  width: 400px;
  max-width: 90vw;
  margin: 0 auto;
  text-align: center;
}

.styles_setNumber__22Jgd {
  display: block;
  padding: 5vh 0;
  font-weight: bold;
}

.styles_image__3f6Rk {
  display: block;
  margin: 0 auto 10vh auto;
  max-width: 90vw;
  max-height: 90vh;
  width: auto;
  height: auto;
}

/* Explanation styles */
.styles_explanationTable__1ntk7 {
  width: 100%;
  /* border: 1px solid black; */
  border-collapse: collapse;
  border-spacing: 0px;
}

.styles_explanationTable__1ntk7 td {
  border: 1px solid black;
  padding: 4px;
}
.styles_projectStyles__aVRe- {}

.styles_contentStyles__3ARoc {
  text-align: center;
  padding: 20vh 10vw 10vh 10vw;
}

.styles_row__2jhsU {
  display: flex;
  width: calc(13 * 550px);
  margin-bottom: 15vh;
}

.styles_image__1rUl0 {
  margin-right: 50px;
}
.styles_projectStyles__3-Xvn {}

.styles_contentStyles__1qlT9 {
  width: 75vw;
  max-width: 80vh;
  padding-top: 70px;
  margin: 0 auto;
}

.styles_video__2TA98 {
  margin-bottom: 10vh;
  border: 1px solid gray;
}
.styles_contentStyles__2yRR7 {
  text-align: center;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styles_image__12GAT {
  max-width: 100%;
  max-height: 100vh;
  width: auto;
  height: auto;
  margin-bottom: 15vh;
}

.styles_words__2vDLa {
  display: flex;
  justify-content: space-evenly;
  font-style: italic;
  padding: 0.5vh 0;
  width: 100%;
}

.styles_placeholder__2URYC {
  max-width: 100%;
  max-height: 100vh;
  width: auto;
  height: auto;
  margin: 0;
  margin-bottom: 15vh;
}
.styles_projectStyles__3XTv5 {}

.styles_contentStyles__1noDR {
  /* width: 90vw; */
  max-width: 90vw;
  padding: 15vh 0 20vh 0;
  margin: 0 auto;
}

.styles_video__1zfUj {
}

.styles_row__3YWX9 {
  padding-top: 15vh;
}
.styles_arbol__3dwit {
  max-width: 25%;
}
.styles_arboles__1UXZF {
  padding-top: 15vh;
}
.styles_plant__1GPEO {
  margin-top: 20vh;
  max-width: 35%;
}
.styles_rastro__10ffJ {
  max-width: 40%;
}
.styles_secondPlant__1Kyuh {
  max-width: 40%;
}
.styles_thirdPlant__26NW8 {
  max-width: 40%;
}
.styles_plaza__apXeU {
  max-width: 40%;
}
.styles_gloves__GU-o8 {
  margin-top: 15vh;
  max-width: 35%;
}
.styles_light__2I6An {
  margin-top: 15vh;
  max-width: 20%;
}
.ColorScale_scale__1MTYj {
  background: gray;
  height: 300px;
  width: 36px;
  position: fixed;
  bottom: 3.5vh;
  right: 2.5vw;
}

.ColorScale_color__2EO7I {
  background: gray;
}
.Explanation_explanation__2tHAr {
  position: fixed;
  min-width: 450px;
  max-width: 500px;
  max-height: 75vh;
  top: 80px;
  right: 30px;
  padding: 30px;
  border: 1px solid transparent;
  overflow-y: hidden;
}

.Explanation_explanation__2tHAr:hover {
  background: white;
  border: 1px solid black;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.Explanation_explanation__2tHAr.Explanation_explanationIsHidden__1GRzC {
  min-width: auto;
  padding: 0;
  top: 80px;
}

.Explanation_collapseButton__3T7jB {
  -webkit-appearance: none;
  margin: 0 0 0 0;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  border: none;
  background: none;
  color: red;
  float: right;
  font-family: "Computer Modern Serif", serif;
}

.Explanation_explanationIsHidden__1GRzC .Explanation_collapseButton__3T7jB {
  color: black;
  margin: 0;
}

.Explanation_projectParent__1pRbN {
  font-size: 1.1rem;
  text-transform: lowercase;
  font-weight: normal;
  margin: 0 0 0.5rem 0;
}
.Explanation_projectTitle__1FE-a {
  font-size: 1.25rem;
  margin: 0 0 3rem 0;
}
